// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/cart/CartContents.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/cart/CartContents.tsx");
  import.meta.hot.lastModified = "1716805822000";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { Price } from '~/components/products/Price';
import ProgressBar from '~/components/products/ProgressBar';
import React from 'react';
import { AmountAdjuster } from '~/components/cart/AmountAdjuster';
export function CartContents({
  orderLines,
  currencyCode,
  editable = true,
  adjustOrderLine,
  removeItem
}) {
  const isEditable = editable !== false;
  return <div className="flow-root">
      <ul role="list" className="-my-6 divide-y divide-gray-200">
        {(orderLines ?? []).map(line => <li key={line.id} className="py-6 flex">
            <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
              <img src={line.featuredAsset?.preview + '?preset=thumb'} alt={line.productVariant.name} className="w-full h-full object-center object-cover" />
            </div>

            <div className="ml-4 flex-1 flex flex-col">
              <div>
                <div className="flex justify-between text-base font-bold text-gray-900 ">
                  <h3>
                    <Link to={`/products/${line.productVariant.product.slug}`}>
                      {line.productVariant.name}
                    </Link>
                  </h3>
                  <p className="ml-4">
                    <Price //Font medium and sizing down
                priceWithTax={line.linePriceWithTax} currencyCode={currencyCode}></Price>
                  </p>
                </div>
              </div>

              <p className="flex-1 flex items-center text-sm">
                Bio Knospe Schweiz
              </p>

              <label htmlFor={`quantity-${line.id}`} className="mr-2 cartContent-text">
                Menge in{' '}
                {line.productVariant.customFields?.BaseUnitOfMeasure || 'N/A'}
              </label>

              <div className="flex-1 flex items-center text-sm">
                {editable ? <AmountAdjuster qtyInCart={line.quantity} baseUnitOfMeasure={line.productVariant.customFields?.BaseUnitOfMeasure || 'N/A'} inCart={true} addToCart={() => {}} adjustInCart={amount => adjustOrderLine && adjustOrderLine(line.id, amount)} deleteFromCart={() => removeItem && removeItem(line.id)} cssPostFix="-inCart" showUnit={false} /> : <div className="text-gray-800">
                    <span className="mr-1">Quantity</span>
                    <span className="font-medium">{line.quantity}</span>
                  </div>}

                <div className="flex-1"></div>
                <div className="flex">
                  {isEditable && <button type="submit" name="removeItem" value={line.id} className="font-medium text-primary-600 hover:text-primary-500" onClick={() => removeItem && removeItem(line.id)}>
                      Löschen
                    </button>}
                </div>
              </div>
              <div id="product-catalog-progressbar-button-container">
                <ProgressBar color={'custom-progress-bar'} maxValue={100} currentValue={75} infoTextClassname={'product-catalog-progressbar-text'} baseUnit={'kg'} takeRest={false} />
              </div>
            </div>
          </li>)}
      </ul>
    </div>;
}
_c = CartContents;
var _c;
$RefreshReg$(_c, "CartContents");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;